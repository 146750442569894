<template>
  <div class="card-booking">
    <h6 class="card-booking-title"> CASSIGNOL / CHEVALIER </h6>
    <div class="card-booking-footer">
      <div class="duration">
        10:00 - 12:00
      </div>
      <div class="currency">
        <div class="rounded-currency bck-gradient-warning"><i class="fa fa-eur"></i></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    status: {
      type: Boolean,
      default: false,
    }
  }
}
</script>
<style scoped>

.card-booking {
  background-color: white;
  border-radius: 5pt;
  box-shadow: 0 0 3pt 2pt rgba(0, 0, 0, 0.05);
  width: 100%;
  height: 100%;
  position: relative;
  padding: 4pt;
  border-left: 10px solid #F1A357;
}

.card-booking .card-booking-footer .duration {
  position: absolute;
  bottom: 4pt;
  left: 4pt;
}

.card-booking .card-booking-footer .currency {
  position: absolute;
  bottom: 4pt;
  right: 2px;
}

.card-booking .card-booking-title {
  text-align: left;
  font: normal normal 700 15px arial;
  letter-spacing: -0.23px;
  color: #070B31;
  opacity: 1;
}

.rounded-currency {
  height: 20px;
  width: 20px;
  padding: 1pt 4pt;
  border-radius: 50%;
}
</style>
